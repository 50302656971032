var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tag-list" },
    _vm._l(_vm.memoTags, function (memoTag) {
      return _c(
        "div",
        {
          key: memoTag.id,
          staticClass: "tag-row",
          on: {
            click: function ($event) {
              return _vm.onClickItem(memoTag)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "check-icon" },
            [
              _c("checkbox-icon", {
                attrs: { isChecked: _vm.isChecked(memoTag) },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tag-checkbox" }, [
            _c("label", { attrs: { for: memoTag.id } }, [
              _vm._v(_vm._s(memoTag.name)),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }