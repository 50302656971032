var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c(
      "div",
      { ref: "container", staticClass: "container" },
      [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "title-container" },
              [
                _c("label", [_vm._v(_vm._s(_vm.novelTitle) + "メモ")]),
                _c("sort-icon", {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closePopupMenu,
                      expression: "closePopupMenu",
                    },
                  ],
                  attrs: { onClick: _vm.switchFragment },
                }),
              ],
              1
            ),
            _c("popup-menu", {
              attrs: {
                isOpen: _vm.showSortPopupMenu,
                items: _vm.sortTypes,
                onSelect: _vm.onSelectedItem,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "filter-container" }, [
          _vm.filterTags.length === 0
            ? _c("div", { staticClass: "empty radius-container" }, [
                _c("div", { staticClass: "filter-area" }, [
                  _vm._v("タグで絞り込む"),
                ]),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c("add-circle-icon", {
                      attrs: { onClick: _vm.onFilterClick },
                    }),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "selected radius-container" }, [
                _c(
                  "div",
                  { staticClass: "filter-area" },
                  _vm._l(_vm.filterTags, function (item) {
                    return _c("chip", {
                      key: item.id,
                      attrs: { value: item.name },
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c("remove-circle-icon", {
                      attrs: { onClick: _vm.onFilterClick },
                    }),
                  ],
                  1
                ),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "memo-container" },
          _vm._l(_vm.memos, function (memo) {
            return _c("memo-row", {
              key: memo.id,
              attrs: {
                novelId: _vm.novelId,
                memo: memo,
                onClickItem: _vm.onClickItem,
              },
            })
          }),
          1
        ),
        _c(
          "div",
          {
            staticClass: "create-btn",
            on: { click: _vm.onCreateMemoButtonClick },
          },
          [
            _c("document-outline-icon", { attrs: { fill: "white" } }),
            _c("label", [_vm._v("新規作成する")]),
          ],
          1
        ),
        _vm.showFilterMenu
          ? _c("memo-tag-filter", {
              staticClass: "memo-tag-filter",
              style: _vm.styleMemoTagFilter,
              attrs: {
                onClose: _vm.closeFilterMenu,
                filterTags: _vm.filterTags,
                onSelect: _vm.onSelectedFilter,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }