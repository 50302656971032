var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass: "row",
      class: { active: _vm.isActive },
      on: { click: _vm.onClickMemo },
    },
    [
      _c("div", { staticClass: "title-container" }, [
        _c("label", { attrs: { id: "title" } }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "tag-container" }, [
        _c(
          "div",
          { staticClass: "chip-list" },
          _vm._l(_vm.getSlicedMemoTags(), function (memoTag) {
            return _c("chip", {
              key: memoTag.id,
              attrs: { value: memoTag.name },
            })
          }),
          1
        ),
        _vm.overCount > 0
          ? _c("div", { staticClass: "over-count" }, [
              _vm._v("+" + _vm._s(_vm.overCount)),
            ])
          : _vm._e(),
      ]),
      _c("div", { style: { paddingTop: "2px", paddingBottom: "2px" } }, [
        _c("label", { attrs: { id: "updatedAt" } }, [
          _vm._v("更新日 " + _vm._s(_vm.updatedAt)),
        ]),
      ]),
      _c("hr", { attrs: { noshade: "", size: "1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }