var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "chip", style: _vm.style }, [
    _vm._v(" " + _vm._s(_vm.text) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }