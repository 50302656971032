var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tag-container", class: { open: _vm.isOpen } },
    [
      _c("div", { staticClass: "tag", on: { click: _vm.onOpenTagClick } }, [
        _vm.isOpen
          ? _c("span", [_c("close-icon")], 1)
          : _c("span", [_c("tag-icon")], 1),
      ]),
      _c("div", { staticClass: "detail" }, [
        _c(
          "div",
          {
            staticClass: "novel-select",
            class: { active: _vm.novelSelectable },
          },
          [
            _c("span", { staticClass: "title" }, [_vm._v("作品タグ")]),
            _c("select-box", {
              attrs: {
                novels: _vm.novels,
                selectedItem: _vm.relatedNovel,
                onSelected: _vm.onNovelSelected,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tag-select" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("追加タグ")]),
            _c(
              "div",
              { staticClass: "selected-tag-list" },
              _vm._l(_vm.selectedTags, function (tag) {
                return _c("deleteable-chip", {
                  key: tag.id,
                  attrs: {
                    value: tag.name,
                    item: tag,
                    onDelete: _vm.onUnselectTag,
                  },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "create-tag-container" },
              [
                _c("round-input", {
                  attrs: {
                    placeholder: "新規メモタグ",
                    text: _vm.createMemoTagName,
                    changeText: _vm.onChangeText,
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: {
                      active: _vm.createMemoTagName !== "",
                    },
                    attrs: { disabled: _vm.createMemoTagName === "" },
                    on: { click: _vm.onCreateTagButtonClick },
                  },
                  [_vm._v(" 新規作成 ")]
                ),
              ],
              1
            ),
            _c("tag-check-list", {
              staticClass: "tag-check-list",
              attrs: {
                memoTags: _vm.memoTags,
                selectedMemoTags: _vm.memo.items,
                onClickItem: _vm.onUpdateTagChekbox,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }