var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        height: _vm.iconSize,
        width: _vm.iconSize,
        fill: _vm.fill,
        viewBox: `0 0 24 24`,
      },
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.item)
        },
      },
    },
    [
      _c("path", { attrs: { d: `M0 0h24v24H0z`, fill: "none" } }),
      _c("path", {
        attrs: {
          d:
            "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }