var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "enable-background": "new 0 0 24 24",
        viewBox: "0 0 24 24",
        fill: _vm.fill,
        width: _vm.iconSize,
        height: _vm.iconSize,
      },
    },
    [
      _c("g", [_c("path", { attrs: { d: "M0,0h24v24H0V0z", fill: "none" } })]),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M21.41,11.41l-8.83-8.83C12.21,2.21,11.7,2,11.17,2H4C2.9,2,2,2.9,2,4v7.17c0,0.53,0.21,1.04,0.59,1.41l8.83,8.83 c0.78,0.78,2.05,0.78,2.83,0l7.17-7.17C22.2,13.46,22.2,12.2,21.41,11.41z M6.5,8C5.67,8,5,7.33,5,6.5S5.67,5,6.5,5S8,5.67,8,6.5 S7.33,8,6.5,8z",
            fill: _vm.fill,
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }