var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "select",
      class: { open: _vm.isOpen },
      on: { click: _vm.onSelectBoxClick },
    },
    [
      _c("div", { staticClass: "selected" }, [
        _c("div", { staticClass: "placeholder" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("div", { staticClass: "expand-icon" }),
      ]),
      _c(
        "ul",
        _vm._l(_vm.selectableNovels, function (item) {
          return _c(
            "li",
            {
              key: item.novelId,
              on: {
                click: function ($event) {
                  return _vm.onSelected(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }