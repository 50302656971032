
import Vue from "vue";
import * as lodash from "lodash";
import SlideTagMenu from "@/components/organisms/SlideTagMenu.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import Chip from "@/components/atoms/chips/Chip.vue";
import TagIcon from "@/components/atoms/TagIcon.vue";
import { Memo, MemoTag } from "@/lib/models";
import { MemoStorage } from "@/lib/storages";
import { Dialog } from "@/lib/utils";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

// ソートの種類
const CountType = {
  onlyCharacter: "全文字数",
  addBlank: "空白を含む全文字数",
  addNewLine: "改行を含む全文字数",
  all: "空白/改行を含む全文字数",
} as const;

// enumは使わない方がいいみたいだからunion型にしてみた
// eslint-disable-next-line no-redeclare
type CountType = typeof CountType[keyof typeof CountType];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SlideTagMenu, Chip, TagIcon },
  created() {
    this.initialize();
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  data() {
    const editingMemo = lodash.cloneDeep(this.$store.getters["memoModule/memo"](this.memoId));
    const currentCountType = CountType.onlyCharacter;
    return {
      editingMemo,
      content: "",
      preContent: "",
      currentCountType,
      unbindShortcutKeys: null,
    };
  },
  computed: {
    hasMemoTags() {
      const memoTags = this.selectedMemoTags;
      if (!memoTags) return false;
      if (memoTags.length === 0) return false;

      return true;
    },
    selectedMemoTags() {
      if (this.editingMemo.items) {
        const memoTags = this.editingMemo.items.map((item) => this.memoTags.find((tag) => tag.id === item));
        return memoTags.filter((memoTag): memoTag is MemoTag => typeof memoTag !== "undefined");
      }

      return [];
    },
    counter() {
      const content = lodash.clone(this.content);
      let contentLength = 0;
      switch (this.currentCountType) {
        case CountType.addBlank:
          contentLength = content.replace(/\n/g, "").length;
          break;
        case CountType.addNewLine:
          contentLength = content.replace(/[^\S\n]/g, "").length;
          break;
        case CountType.all:
          contentLength = content.length;
          break;
        case CountType.onlyCharacter:
        default:
          contentLength = content.replace(/\s+|\n/g, "").length;
          break;
      }

      return `${contentLength} 字`;
    },
    memo() {
      return lodash.cloneDeep(this.$store.getters["memoModule/memo"](this.memoId));
    },
    memoTags() {
      return this.$store.state.memoModule.memoTags;
    },
  },
  methods: {
    async initialize() {
      const { memo, onUpdatedIsChanged } = this;

      // 保存ボタンのショートカットキーの有効化と解除関数の取得
      this.unbindShortcutKeys = handleShortcutKeys([
        { trigger: TriggerKey.Meta, keys: ["s"], callback: this.updateMemo },
        { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.updateMemo },
      ]);

      if (memo) {
        const memoStorage = new MemoStorage();
        this.editingMemo = lodash.cloneDeep(memo);
        this.content = await memoStorage.downloadFile(memo);
        this.preContent = lodash.cloneDeep(this.content);
        // eslint-disable-next-line no-unused-expressions
        onUpdatedIsChanged && onUpdatedIsChanged(false);
      } else {
        // 該当するものがない場合は初期値を入れる
        this.editingMemo = {
          id: this.memoId,
          novelId: "",
          title: "",
          items: [],
          s3BucketPath: "",
          createdAt: "",
          updatedAt: "",
        };

        this.content = "";
        this.preContent = "";
        // eslint-disable-next-line no-unused-expressions
        onUpdatedIsChanged && onUpdatedIsChanged(false);
      }
    },
    change() {
      const { onUpdatedIsChanged } = this;

      if (this.memo) {
        if (
          this.memo.title !== this.editingMemo.title ||
          this.preContent !== this.content ||
          this.modifiedNovelId(this.memo.novelId, this.editingMemo.novelId) ||
          this.modifiedMemoTags(this.memo.items, this.editingMemo.items)
        )
          // eslint-disable-next-line no-unused-expressions
          onUpdatedIsChanged && onUpdatedIsChanged(true);
        else {
          // eslint-disable-next-line no-unused-expressions
          onUpdatedIsChanged && onUpdatedIsChanged(false);
        }
      }
    },
    updateMemo() {
      const { $store, isChanged, preContent, content, onUpdatedIsChanged } = this;

      if (!isChanged) return;

      this.editingMemo.items = this.selectedMemoTags.map((memoTag) => memoTag.id);
      $store.dispatch("memoModule/updateMemo", {
        memo: this.editingMemo,
        content: preContent !== content ? content : null,
        callback: () => {
          this.preContent = lodash.cloneDeep(content);
          // eslint-disable-next-line no-unused-expressions
          onUpdatedIsChanged && onUpdatedIsChanged(false);

          this.$notify({
            title: "保存しました",
            type: "success",
          });
        },
      });
    },
    deleteMemo() {
      this.$store.dispatch("memoModule/deleteMemo", {
        id: this.memoId,
        callback: this.deleteCallBack,
      });
    },
    deleteCallBack() {
      this.$notify({
        title: "削除しました",
        type: "error",
      });

      if (Object.prototype.hasOwnProperty.call(this.$route.params, "novelId")) {
        this.$router.push({
          name: this.$route.name as string,
          params: { novelId: this.novelId },
        });
        return;
      }
      if (Object.prototype.hasOwnProperty.call(this.$route.query, "novelId")) {
        this.$router.push({
          name: this.$route.name as string,
          query: { novelId: this.novelId },
        });
        return;
      }

      this.$router.push({ name: this.$route.name as string });
    },
    onSaveButtonClick() {
      this.updateMemo();
    },
    onDeleteButtonClick() {
      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "メモを削除しますか？",
        content: "本当にメモを削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback: () => this.deleteMemo(),
      };
      confirmDialog.show(data);
    },
    modifiedNovelId(a, b) {
      let x = "";
      let y = "";
      if (a !== undefined && a !== null) x = a;

      if (b !== undefined && b !== null) y = b;

      return x !== y;
    },
    modifiedMemoTags(a, b) {
      let x: string[] = [];
      let y: string[] = [];

      if (a !== undefined && a !== null) x = lodash.cloneDeep(a);

      if (b !== undefined && b !== null) y = lodash.cloneDeep(b);

      return !lodash.isEqual(x.sort(), y.sort());
    },
  },
  props: {
    memoId: String,
    novelId: String,
    isChanged: Boolean,
    inNovel: {
      type: Boolean,
      default: false,
    },
    onUpdatedIsChanged: Function,
    countTypes: {
      type: Array,
      default: () => Object.values(CountType),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route: "initialize",
  },
});

interface Data {
  editingMemo: Memo;
  content: string;
  preContent: string;
  currentCountType: CountType;
  unbindShortcutKeys: (() => void) | null;
}

interface Computed {
  hasMemoTags: boolean;
  selectedMemoTags: MemoTag[];
  counter: string;
  memo: Memo;
  memoTags: MemoTag[];
}

interface Methods {
  initialize: (updated?: boolean) => void;
  change: (e: InputEvent) => void;
  updateMemo: () => void;
  deleteMemo: () => void;
  modifiedNovelId: (a: null | undefined | string, b: null | undefined | string) => boolean;
  modifiedMemoTags: (a: null | undefined | string[], b: null | undefined | string[]) => boolean;
  onDeleteButtonClick: () => void;
  onSaveButtonClick: () => void;
  deleteCallBack: () => void;
}

interface Props {
  memoId: string;
  novelId: string;
  inNovel: boolean;
  isChanged: boolean;
  countTypes: string[];
  onUpdatedIsChanged?: (isChanged: boolean) => void;
  readonly: boolean;
}
