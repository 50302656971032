var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.memoId
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _c("slide-tag-menu", {
              attrs: {
                memo: _vm.editingMemo,
                onChange: _vm.change,
                novelSelectable: !_vm.inNovel,
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editingMemo.title,
                  expression: "editingMemo.title",
                },
              ],
              staticClass: "title",
              attrs: {
                type: "text",
                placeholder: "タイトル",
                maxlength: "50",
                readonly: _vm.readonly,
              },
              domProps: { value: _vm.editingMemo.title },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.editingMemo, "title", $event.target.value)
                  },
                  _vm.change,
                ],
              },
            }),
            _c("div", { staticClass: "text" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.content,
                    expression: "content",
                  },
                ],
                staticClass: "textarea",
                attrs: {
                  disabled: _vm.content === null,
                  placeholder: "本文",
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.content },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.content = $event.target.value
                    },
                    _vm.change,
                  ],
                },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "selected-tag-container",
                class: { none: !_vm.hasMemoTags },
              },
              [
                _c("div", { staticClass: "tag-icon" }, [_c("tag-icon")], 1),
                _c(
                  "div",
                  { staticClass: "tag-chip-list" },
                  _vm._l(_vm.selectedMemoTags, function (memoTag) {
                    return _c("chip", {
                      key: memoTag.id,
                      attrs: { value: memoTag.name },
                    })
                  }),
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "bottom" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { active: _vm.isChanged },
                    attrs: { disabled: !_vm.isChanged },
                    on: { click: _vm.onSaveButtonClick },
                  },
                  [_vm._v(" 保存する ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button delete",
                    on: { click: _vm.onDeleteButtonClick },
                  },
                  [_vm._v("削除する")]
                ),
              ]),
              _c("div", { staticClass: "text-counter" }, [
                _c("div", { staticClass: "mode-select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentCountType,
                          expression: "currentCountType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currentCountType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.countTypes, function (countType) {
                      return _c(
                        "option",
                        { key: countType, domProps: { value: countType } },
                        [_vm._v(" " + _vm._s(countType) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" " + _vm._s(_vm.counter) + " "),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }