var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tag-filter-container" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title ellipsis", on: { click: _vm.onClose } }, [
        _vm._v("× ウィンドウを閉じる"),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("tag-check-list", {
          attrs: {
            memoTags: _vm.memoTags,
            selectedMemoTags: _vm.selectedTags,
            onClickItem: _vm.onSelect,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }