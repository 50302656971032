var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "chip", style: _vm.style }, [
    _c("div", { staticClass: "chip text" }, [
      _vm._v(" " + _vm._s(_vm.text) + " "),
    ]),
    _c(
      "div",
      { staticClass: "close-btn" },
      [
        _c("close-circle-icon", {
          attrs: {
            height: _vm.iconSize,
            width: _vm.iconSize,
            fill: _vm.iconColor,
            item: _vm.item,
            onClick: _vm.onDelete,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }